<template>
    <v-card :loading="loading">
        <v-form ref="form_endereco">
            <v-card-text>
                <v-row dense>
                    <v-col :xs="12" :xl="2" :sm="2" :md="2" :lg="2" :cols="12" >
                        <!-- SELECIONA O TIPO DE ENDEREÇO -->
                        <v-autocomplete
                        v-model="endereco.tipo"
                        :items="tipos"
                        item-value="Id"
                        item-text="nome"
                        return-object
                        label="Tipo"
                        outlined
                        hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col v-show="habilita_tipo" :xs="12" :xl="2" :sm="2" :md="2" :lg="2" :cols="12">
                        <v-text-field
                        v-model="endereco.cep"
                        label="CEP"
                        outlined
                        v-mask="'#####-###'"
                        @change="get_cep(endereco.cep)"
                        hide-details
                        ></v-text-field>
                    </v-col>
                    
                    <v-col v-show="habilita_tipo" :xs="12" :xl="2" :sm="3" :md="3" :lg="3" :cols="12" >
                        <v-text-field
                            :loading="loading"
                            v-model="endereco.logradouro"
                            label="Logradouro"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col v-show="habilita_tipo" :xs="12" :xl="2" :sm="12" :md="1" :lg="1" :cols="12" >
                        <v-text-field
                            :loading="loading"
                            v-model="endereco.nr"
                            label="Nr"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col v-show="habilita_tipo" :xs="12" :xl="2" :sm="1" :md="1" :lg="1" :cols="12" >
                        <v-autocomplete
                            :loading="loading_ufs"
                            v-model="endereco.uf"
                            :items="ufs"
                            item-value="Id"
                            item-text="sigla"
                            label="UF"
                            outlined
                            @change="get_localidades(endereco)"
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col v-show="habilita_tipo" class="mx-0 px-0" :xs="12" :xl="2" :sm="3" :md="3" :lg="3" :cols="12" >
                        <v-autocomplete
                            v-model="endereco.localidade"
                            label="Localidade"
                            :loading="loading_localidades"
                            :items="localidades"
                            item-value="Id"
                            item-text="localidade"
                            return-object
                            outlined
                            @change="get_bairros(endereco)"
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    
                </v-row>
                
                <v-row dense align="center" class="my-0 py-0">
                    <v-col v-show="habilita_tipo"  :xs="12" :xl="4" :sm="4" :md="4" :lg="4" :cols="12" >
                        <v-text-field
                            v-model="endereco.bairro"
                            :items="bairros"
                            label="Bairro"
                            outlined
                            :loading="loading_bairros"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col v-show="habilita_tipo" :xs="12" :xl="5" :sm="5" :md="5" :lg="5" :cols="12" >
                        <v-text-field
                            v-model="endereco.complemento"
                            label="Complemento"
                            outlined
                            :loading="loading"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col 
                        v-show="habilita_tipo || endereco.id" 
                        :xs="12" :xl="12" :sm="1" :md="1" :lg="1" :cols="12">
                        <v-checkbox
                            v-model="endereco.ativo"
                            label="Ativo"
                            outlined
                            v-show="endereco.id"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        v-show="habilita_tipo" 
                        class="text-right" 
                        v-if="endereco.id" :xs="12" :xl="2" :sm="2" :md="2" :lg="2" :cols="12"
                    >
                        <v-btn icon @click="update_endereco(endereco)">
                            <v-icon size="40">mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn icon @click="cancel_endereco">
                            <v-icon size="40">mdi-cancel</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col v-show="habilita_tipo" class="text-right" v-else :xs="12" :xl="2" :sm="2" :md="2" :lg="2" :cols="12" >
                        <v-btn  
                            @click="save_endereco" 
                            icon
                        >
                            <v-icon size="40">mdi-content-save</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row dense class="mt-10">
                    <v-col :xl="12" :sm="12" :md="12" :lg="12" :cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="enderecos"
                            :loading="loading_enderecos"
                        >
                            <template v-slot:[`item.ativo`]="item">
                                <v-chip
                                    class="ma-2"
                                    :color="item.item.ativo?'blue':'grey'"
                                >
                                    <span class="white--text">{{item.item.ativo?'Ativo':'Inativo'}}</span>
                                </v-chip>
                            </template>
                            <template v-slot:[`item.acoes`]="item">
                                <v-btn icon @click="edit_item(item.item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                    
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
    import api from '@/http'
    export default{
    name: "endereco",
    components: {},
    props:{
        objectCurrent:{
            type:Object
        },
        modulo:{
            type:String,
            default:null
        }
    },
    data: () => ({
        loading_enderecos: false,
        loading_tipos: false,
        loading_bairros: false,
        loading_localidades: false,
        loading_ufs: false,
        loading: false,
        tipos: [],
        enderecos: [],
        localidades: [],
        bairros: [],
        ufs: [],
        endereco: {
            id: null,
            cep: null,
            logradouro: null,
            nr: null,
            bairro: '',
            complemento: null,
            uf: { id: null, nome: null },
            localidade: { id: null, nome: null },
            tipo: { id: null, nome: null },
            acoes: null,
        },
        headers: [
            { text: "Id", value: "id" },
            { text: "Tipo", value: "tipo.nome" },
            { text: "CEP", value: "cep" },
            { text: "Logradouro", value: "logradouro" },
            { text: "Nrº", value: "nr" },
            { text: "Bairro", value: "bairro" },
            { text: "Complemento", value: "complemento" },
            { text: "UF", value: "uf.sigla" },
            { text: "Município", value: "municipio" },
            { text: "Status", value: "ativo" },
            { text: "Editar", value: "acoes" },
        ]
    }),
    methods: {
        get_enderecos() {
            this.loading_enderecos = true;
            let endpoint = `contas/enderecos/?enderecos=${this.objectCurrent.id}`
            if(this.modulo!==null){
                endpoint = `${this.modulo}/enderecos/?enderecos=${this.objectCurrent.id}`
            }
            api(endpoint)
            .then((e)=>{
                this.enderecos = e.data;
            })
            .finally(()=>{
                this.loading_enderecos = false
            })
        },
        get_tipos() {
            this.loading_tipos = true;
            api(`contas/tipos-enderecos/`)
            .then((e)=>{
                this.tipos = e.data
            })
            .finally(()=>{
                this.loading_tipos = false
            })
        },      
        get_bairros(obj=null, bairro=null) {
            this.ativa_loadings()
            this.loading_bairros = true;
            let url = "uteis/bairros"
            let params = ''
            if(obj!==null && typeof obj.localidade === 'object'){
                params = obj!==null?`/?localidade=${obj.localidade.localidade}&uf=${obj.uf}`:``
            }else{
                params = obj!==null?`/?localidade=${obj.localidade}&uf=${obj.uf}`:``
            }
            api(url+params)
            .then((e)=>{
                this.bairros = e.data;        
                if(bairro!==null){
                    this.endereco.bairro = bairro[0]
                }
            }).finally(()=>{
                this.cancela_loadings()
            })
        },
        get_localidades(obj=null, atualiza=false) {
            this.loading_localidades = true; 
            
            let url = "uteis/localidades"
            let params = ""
            if(obj!==null && typeof obj.uf === 'object'){
                params = obj!==null?`/?uf=${obj.uf.silga}`:``
            }else{
                params = obj!==null?`/?uf=${obj.uf}`:``
            }
            api(url+params).then((e)=>{
                this.localidades = e.data;
                if(atualiza){
                    let mun = this.carrega_set_localidade(e.data, obj)                   
                    this.atualiza_campos_form_endereco(obj, mun)
                    // this.get_bairro(obj)
                }
                
            })
            .catch((e)=>e)
            .finally(()=>{
                this.loading_localidades = false;
            })
        },
        get_ufs() {
            this.loading_ufs = true;
            api(`uteis/uf/`).then((e)=>{
                this.ufs = e.data
            }).finally(()=>{
            this.loading_ufs = false
            })
        },
        post_localidade(obj){
            
            this.loading_tipos = true
            this.loading_bairros = true
            this.loading_localidades = true
            this.loading_ufs = true
            api.post(`uteis/localidades/`,obj).then(()=>{
                this.get_localidades()
                this.atualiza_campos_form_endereco(obj)

            }).finally(()=>{
                this.loading_tipos = false
                this.loading_bairros = false
                this.loading_localidades = false
                this.loading_ufs = false
            })
        },
        get_localidade(obj) { 
            this.loading_localidades = true
            api(`uteis/localidades/?localidade=${obj.localidade}`).then((e)=>{
                if(e.data.length==0){
                    this.post_localidade(obj);
                }
            })
        },
        post_bairro(obj){ 
            if(obj.bairro.length){
                api.post(`uteis/bairros/`,obj)
                .then((e)=>{
                    this.get_bairros()
                    e
                    alert('Add o bairro em atualizar base de endereço')
                })
            }
        },
        get_bairro(obj) { 
            api(`uteis/bairros/?bairro=${obj.bairro}&uf=${obj.uf}&localidade=${obj.localidade}`)
            .then((e)=>{
                if(e.data.length>0){
                    let bairro = this.carrega_set_bairro(e.data, obj)
                    this.get_bairros(obj, bairro)
                    

                }else{
                    if(obj.bairro.length>0){
                        this.post_bairro(obj);
                    }else{
                        console.log('add bairro via ')
                    }
                    //
                }
            }).catch((e)=>{
                console.log(e)
            })
            
        },
        get_cep(val){
            this.ativa_loadings()

            this.axios(`https://viacep.com.br/ws/${val}/json/`)
            .then((e)=>{
                let obj = e.data
                /*
                a api de cep, pode retornar status 200 e mesmo assin 
                nao trazer nada, nesta situcao ela retornar um obj 
                com chave erro e valor `true`
                */
                 if(obj.erro==`true`){
                    alert('Cep não encontrado :/')
                }else{
                    // verifica se há uf e localidade
                    if(obj.uf !==null && obj.localidade !==null){
                        this.get_localidades(obj, true)  
                    }
                }
            })
            .catch((e)=>{
                let msg = e
                alert(`CEP no formato errado ${msg}`)
            }) 
            .finally(()=>{this.cancela_loadings})
        },
        atualiza_campos_form_endereco(obj, mun){
            this.loading = true;
            mun
            let localidade = this.localidades.filter((e)=>e.localidade == obj.localidade)[0]
            this.endereco.bairro = obj.bairro
            this.endereco.complemento = obj.complemento
            this.endereco.logradouro = obj.logradouro
            this.endereco.localidade = localidade
            this.endereco.uf = localidade.uf   
        
            this.loading = false;
            this.loading_tipos = false
            this.loading_bairros = false
            this.loading_localidades = false
            this.loading_ufs = false
        },
        cancela_loadings(){
            this.loading = false
            this.loading_tipos = false
            this.loading_bairros = false
            this.loading_localidades = false
            this.loading_ufs = false
        },
        carrega_set_localidade(localidades, obj){
            return localidades.filter((e)=>{
                return e.uf.sigla == obj.uf && e.localidade == obj.localidade
            })
        },
        carrega_set_bairro(bairro, obj){
            return bairro.filter((e)=>{
                return e.bairro == obj.bairro
            })
        },
        ativa_loadings(){
            this.loading = false
            this.loading_tipos = false
            this.loading_bairros = false
            this.loading_localidades = false
            this.loading_ufs = false
        },
        save_endereco(){
            this.loading_enderecos = true; 
            this.endereco.usuario = this.objectCurrent.id
            api.post(`${this.modulo}/enderecos/`, this.endereco).then(()=>{
                this.get_enderecos()       
                this.$emit('emit_atualiza_enderco', true) 
            }).catch((e)=>{ 
                console.log(e)
            }).finally(()=>{    this.loading_enderecos = false; });
        },
        edit_item(obj){
            this.loading_localidades = true;
            api(`uteis/localidades/?uf=${obj.uf.id}`)
            .then((e) => {
                this.localidades = e.data
                this.endereco = obj
                this.endereco.localidade = obj.localidade
                this.endereco.uf = obj.uf
                this.endereco.tipo = obj.tipo
                this.$emit('emit_atualiza_enderco', true) 
            })
            .finally(()=>{ this.loading_localidades = false; });
        },
        update_endereco(obj){
            this.loading_enderecos = true;
            api.put(`${this.modulo}/endereco/${obj.id}/`,obj)
            .then(()=>{
                this.get_enderecos()
            })
            .finally(()=>{ this.loading_enderecos = false; });
        },
        cancel_endereco(){
            this.endereco = {
                id: null,
                cep: null,
                logradouro: null,
                nr: null,
                bairro: '',
                complemento: null,
                uf: { id: null, nome: null },
                localidade: { id: null, nome: null },
                tipo: { id: null, nome: null },
                acoes: null
            }
        }
        
    },
    computed: {
        habilita_tipo(){
            return this.endereco.tipo.id!==null?true:false;
        }
    },
    watch: {},
    mounted() {
        // Carrega os tipos de endereços
        this.get_tipos();
        // Carrega os endereços salvos por colaborador
        this.get_enderecos();
        // Carrega as Unidades Federativas da União
        this.get_ufs();
    }
}
</script>